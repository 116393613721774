// ScrollToTop.js
import React, { useEffect } from "react";
import { useLocation } from "react-router";

const ScrollToTop = props => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  // eslint-disable-next-line react/prop-types
  return <>{props.children}</>;
};

export default ScrollToTop;
